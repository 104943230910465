import { toast, ToastContainer } from "react-toastify";
import { server_url } from "../../../../helpers/auth";
import axios from "axios";
import { useEffect, useState } from "react";

const AdLeaderBoard = () => {
  document.title = "APEX || GAME DAY ";
  const [data, setData] = useState("");
  const loc = window.location;
  const getData = async () => {
    try {
      const link = `${server_url}/api/get_gameday`;

      let res = await axios.get(link);
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);
  const Add = () => {
    const submit_handler = async (e) => {
      try {
        e.preventDefault();
        const p_name = e.target.p_name.value;
        const link = e.target.link.value;

        if (!p_name || !link) {
          return toast.error("Fill all fields.");
        }
        const url = `${server_url}/api/add_gameday/`;
        let _token = localStorage.getItem("token");
        let obj = {
          p_name,
          link,
          _token,
        };
        let res = await axios.post(url, obj);
        if (res.data.success) {
          e.target.reset();
          getData();
          return toast.success(`Streamer ${p_name} added.`);
        }
      } catch (error) {
        return toast.error(
          error.response.data.message || "Something went wrong"
        );
      }
    };
    return (
      <>
        <div className="container">
          <h1>ADD Streamers</h1>
          <div className="add_player">
            <form onSubmit={submit_handler}>
              <div className="inputs">
                <input name="p_name" type="text" placeholder="Name" />

                <input name="link" type="text" placeholder="Live Stream Link" />
              </div>
              <input type="submit" value={"ADD"} />
            </form>
          </div>
        </div>
      </>
    );
  };
  // const Pre = (p, i) => {
  //   const delete_handler = async () => {
  //     try {
  //       let con = window.confirm("Are you sure want to delete?");
  //       if (!con) return;
  //       const link = `${server_url}/api/delete_gameday/`;
  //       let _token = localStorage.getItem("token");

  //       const submit_obj = {
  //         _id: p._id,
  //         _token,
  //       };
  //       let res = await axios.post(link, submit_obj);
  //       if (res.data.success) {
  //         toast.warn(`Player ${p.tag} deleted.`);
  //         getData();
  //       }
  //     } catch (error) {
  //       return toast.error(
  //         error.response.data.message || "Something went wrong"
  //       );
  //     }
  //   };
  //   const submit_handler = async (e) => {
  //     try {
  //       e.preventDefault();
  //       const p_name = e.target.p_name.value;
  //       const link = e.target.link.value;

  //       if (!p_name || !link) {
  //         return toast.error("Fill all fields.");
  //       }
  //       const url = `${server_url}/api/update_gameday/`;
  //       let _token = localStorage.getItem("token");
  //       let obj = {
  //         p_name,
  //         link,
  //         _id: p._id,
  //         _token,
  //       };
  //       let res = await axios.post(url, obj);
  //       if (res.data.success) {
  //         toast.success(`Player ${p_name} updated.`);
  //         getData();
  //       }
  //     } catch (error) {
  //       return toast.error(
  //         error.response.data.message || "Something went wrong"
  //       );
  //     }
  //   };
  //   return (
  //     <>
  //       <div key={`_d${i}`} className="pre_players">
  //         <form onSubmit={submit_handler}>
  //           <div className="inputs">
  //             <input
  //               type="text"
  //               name="p_name"
  //               defaultValue={p.p_name}
  //               id=""
  //               placeholder="Name"
  //             />
  //             <input
  //               type="text"
  //               name="link"
  //               defaultValue={p.link}
  //               id=""
  //               placeholder="Link"
  //             />
  //           </div>
  //           <button className="button_2">Update</button>
  //         </form>
  //         <button onClick={delete_handler} className="button_2 delete">
  //           Delete
  //         </button>
  //       </div>
  //     </>
  //   );
  // };

  const Tr = (p, i) => {
    const delete_handler = async () => {
      try {
        let con = window.confirm("Are you sure want to delete?");
        if (!con) return;
        const link = `${server_url}/api/delete_gameday/`;
        let _token = localStorage.getItem("token");

        const submit_obj = {
          _id: p._id,
          _token,
        };
        let res = await axios.post(link, submit_obj);
        if (res.data.success) {
          toast.warn(`Player ${p.p_name} deleted.`);
          // getData();
          loc.reload();
        }
      } catch (error) {
        return toast.error(
          error.response.data.message || "Something went wrong"
        );
      }
    };
    const submit_handler = async () => {
      try {
        const p_name = document.getElementById(`_p_name_${i + 1}`).value;
        const link = document.getElementById(`_link_${i + 1}`).value;

        if (!p_name || !link) {
          return toast.error("Fill all fields.");
        }
        const url = `${server_url}/api/update_gameday/`;
        let _token = localStorage.getItem("token");
        let obj = {
          p_name,
          link,
          _id: p._id,
          _token,
        };
        let res = await axios.post(url, obj);
        if (res.data.success) {
          toast.success(`Player ${p_name} updated.`);
          getData();
        }
      } catch (error) {
        console.log(error);
        return toast.error(
          error?.response?.data?.message || "Something went wrong"
        );
      }
    };
    return (
      <>
        <tr key={`_d${i + 1}`}>
          <th>{i + 1}.</th>

          <td>
            <input
              className="s_inp"
              type="text"
              id={`_p_name_${i + 1}`}
              name="p_name"
              defaultValue={p.p_name}
              placeholder="Name"
            />
          </td>
          <td>
            <input
              className="s_inp"
              id={`_link_${i + 1}`}
              type="text"
              name="link"
              defaultValue={p.link}
              placeholder="Link"
            />
          </td>
          <td>
            <button className="button_2" onClick={submit_handler}>
              Update
            </button>
          </td>

          <td>
            <button onClick={delete_handler} className="button_2 delete">
              Delete
            </button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <h1>GAME DAY</h1>
      <h1>Streamers</h1>
      <div className="container">
        <div className="pre_players_wrap">
          {/* {data && data.length !== 0 ? data.map(Pre) : "No Player Found Yet!"} */}
        </div>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Link</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody id="day_tbody">
            {data && data.length !== 0 ? data.map(Tr) : "No Player Found Yet!"}
          </tbody>
        </table>
      </div>

      <Add />
    </>
  );
};

export default AdLeaderBoard;
