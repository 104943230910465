import { ToastContainer, toast } from "react-toastify";
import { server_url } from "../../../../helpers/auth";
import axios from "axios";
import { useEffect, useState } from "react";

const AdTournamentsResult = () => {
  document.title = "APEX || Results";
  const [data, setData] = useState("");

  const getData = async () => {
    try {
      const link = `${server_url}/api/get_results`;

      let res = await axios.get(link);
      if (res.data.success) {
        console.log(res.data.data);
        setData(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);

  const submit_handler = async (e) => {
    try {
      e.preventDefault();
      // toast.warning("Sending Data..");

      const team_name = e.target.team_name.value;
      const plartform = e.target.plartform.value;
      const place = e.target.place.value;
      const players = document.getElementById("team_players_input").children;
      let players_array = [];

      for (const key of Object.keys(players)) {
        let inputs = players[key].children;

        let name = inputs[0].value;
        let profile = inputs[1].value;
        if (!name || !profile) {
          return toast.error("Players Inputs Must be filled");
        }
        let player = {
          name,
          profile,
        };
        players_array.push(player);
      }

      const url = `${server_url}/api/add_results`;
      let _token = localStorage.getItem("token");

      const send_data = {
        plartform,
        place,
        team_name,
        players: players_array,
        _token,
      };
      // console.log(send_data);
      let res = await axios.post(url, send_data);

      if (res.data.success) {
        e.target.reset();
        document.getElementById("team_players_input").innerHTML = "";
        window.location.reload();
        return toast.success("Result has created.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const add_player = () => {
    let div = document.getElementById("team_players_input");

    let html = `<div><input type="text" name={'name'} placeholder="Player Name" /> <input type="text" name={'profile'} placeholder="Player Profile" /></div>`;
    div.innerHTML += html;
  };

  const Pre = (r, i) => {
    const Player = (p, i) => {
      const player_delete = async () => {
        try {
          let con = window.confirm("Are you sure want to delete?");
          if (!con) {
            return;
          }
          let url = `${server_url}/api/result_player_delete`;
          const _token = localStorage.getItem("token");
          let obj = {
            _token,
            _id: r._id,
            p_id: p._id,
          };
          let res = await axios.post(url, obj);
          if (res.data.success) {
            window.location.reload();

            return toast.warning("Player Updated");
          }
        } catch (error) {
          console.log(error);
        }
      };
      return (
        <>
          <div className="edit_player">
            <p>PLAYER {i + 1}</p>
            <input
              className="s_inp"
              type="text"
              defaultValue={p.name}
              placeholder="Player Name"
            />
            <input
              className="s_inp"
              type="text"
              defaultValue={p.profile}
              placeholder="Player Profile"
            />
            <button className="sub_2" onClick={player_delete}>
              Delete
            </button>
          </div>
        </>
      );
    };

    const delete_handler = async () => {
      try {
        let con = window.confirm("Are you sure want to delete?");
        if (!con) {
          return;
        }
        let url = `${server_url}/api/delete_results`;
        const _token = localStorage.getItem("token");
        let obj = {
          _token,
          _id: r._id,
        };
        let res = await axios.post(url, obj);
        if (res.data.success) {
          window.location.reload();
          toast.warning("Result Deleted!");
        }
      } catch (error) {
        toast.error("Something went wrong.");
      }
    };

    const updater = async () => {
      try {
        const team_name = document.getElementById(`_team_name_${i + 1}`).value;
        const plartform = document.getElementById(`_plartform_${i + 1}`).value;
        const place = document.getElementById(`_place_${i + 1}`).value;
        const players = document.getElementById(
          `_player_div_${i + 1}`
        ).children;

        let players_array = [];

        for (const key of Object.keys(players)) {
          let inputs = players[key].children;

          let name = inputs[1].value;
          let profile = inputs[2].value;
          let player = {
            name,
            profile,
          };
          players_array.push(player);
        }
        let _token = localStorage.getItem("token");
        let submit_obj = {
          team_name,
          plartform,
          place,
          players: players_array,
          _token,
          _id: r._id,
        };
        // console.log(submit_obj);
        let url = `${server_url}/api/update_results`;
        let res = await axios.post(url, submit_obj);
        if (res.data.success) {
          getData();
          return toast.success("Result Updated");
        }
      } catch (error) {
        toast.error("Something went wrong.");
      }
    };
    const callapse = () => {
      document
        .getElementById(`collapseExample${i}`)
        .classList.toggle("collapse");
      document
        .getElementById(`collapseExamplebtn${i}`)
        .classList.toggle("bg-dark");
    };
    return (
      <>
        <tr key={`_r${i + 1}`}>
          <th>{i + 1}.</th>

          <td>
            <input
              className="s_inp"
              type="text"
              id={`_team_name_${i + 1}`}
              name="team_name"
              defaultValue={r.team_name}
              placeholder="Name"
            />
          </td>
          <td>
            <input
              className="s_inp"
              id={`_plartform_${i + 1}`}
              type="text"
              name="plartform"
              defaultValue={r.plartform}
              placeholder="Platform"
            />
          </td>
          <td>
            <input
              className="s_inp"
              id={`_place_${i + 1}`}
              type="text"
              name="place"
              defaultValue={r.place}
              placeholder="Place"
            />
          </td>
          <td>
            {" "}
            <button
              id={`collapseExamplebtn${i}`}
              className="button_2"
              type="button"
              onClick={callapse}
            >
              Players: [{r.players.length}]
            </button>{" "}
            <div className="collapse" id={`collapseExample${i}`}>
              <div className="" id={`_player_div_${i + 1}`}>
                {r.players.map(Player)}
              </div>
            </div>
          </td>
          <td>
            <button className="button_2" onClick={updater}>
              Update
            </button>
          </td>

          <td>
            <button onClick={delete_handler} className="button_2 delete">
              Delete
            </button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <h1>RESULTS MANAGEMENT</h1>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Team Name</th>
              <th scope="col">Platform</th>
              <th scope="col">Place</th>
              <th scope="col">Players</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody id="day_result">
            {data && data.length !== 0
              ? data.map(Pre)
              : "No Result Data Found!"}
          </tbody>
        </table>
        <form className="tour_create_form" onSubmit={submit_handler}>
          <h2>Create Result</h2>
          <div className="inputs">
            <input type="text" name="team_name" placeholder="Team Name" />
            <input type="text" name="plartform" placeholder="Team Platform" />
            <input type="text" name="place" placeholder="Team Place" />
          </div>
          <div id="team_players_input"></div>
          <button type="button" className="sub_2" onClick={add_player}>
            Add Player
          </button>
          <input type="submit" value={"CREATE"} />
        </form>
      </div>
    </>
  );
};

export default AdTournamentsResult;
