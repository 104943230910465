import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import AdPreTournaments from "./AdPreTournaments";
import { server_url } from "../../../../helpers/auth";
import process_image from "../../../../helpers/image_handler";

const AdTournaments = () => {
  document.title = "APEX || Tournaments ";
  

  const submit_handler = async (e) => {
    try {
      e.preventDefault();
      toast.warning("Sending Data..");

      const name = e.target.name.value;
      const link = e.target.link.value;
      // const avater = e.target.avater;
      // const status = e.target.status.value;
      const banner = await process_image(e.target.banner.files[0]);
      // const p = e.target.p.value;

      if (!name || !banner || !link) {
        return toast.error("Fill all the inputs.");
      }

      const url = `${server_url}/api/add_past_tournaments`;
      let _token = localStorage.getItem("token");

      const send_data = {
        name,
        link,
        banner,
        _token,
      };
      // console.log(send_data);
      let res = await axios.post(url, send_data);

      if (res.data.success) {
        e.target.reset();
        window.location.reload()
       return toast.success("Tournament has created.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Create = () => {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AdPreTournaments />
        <div className="container">
          <form className="tour_create_form" onSubmit={submit_handler}>
            <h2>Create Past Tournaments</h2>
            <div className="inputs">
              <input
                type="text"
                name="name"
                id=""
                placeholder="Tournament Name"
              />
              <input
                type="text"
                name="link"
                id=""
                placeholder="Tournament Video Link"
              />

              <div className="files">
                <input
                  accept="image/png, image/jpg"
                  type="file"
                  name="banner"
                  id="fileupload_av"
                  placeholder="Tournament Banner"
                />
                <label htmlFor="fileupload_av">Banner</label>
              </div>
            </div>
            <input type="submit" value={"CREATE"} />
          </form>
        </div>
      </>
    );
  };

  return (
    <>
      <Create />
    </>
  );
};
export default AdTournaments;
