import style from "./footer.module.css";
const Footer = () => {
  return (
    <footer className={`text-white text-center text-lg-start ${style.footer}`}>
      <div
        className="text-center p-3"
        style={{ backgroundcolor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2022 Copyright: {""}
        <a
          className="text-light text-uppercase"
          href="https://theapexbattalion.com"
        >
          THE APEX BATTALION
        </a>
      </div>
    </footer>
  );
};
export default Footer;
