import { useEffect, useState } from "react";
import axios from "axios";
import { server_url } from "../../../../helpers/auth";
import { toast, ToastContainer } from "react-toastify";
import process_image from "../../../../helpers/image_handler";

const AdPreTournaments = () => {
  const [tourData, setTourData] = useState("");
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    try {
      setLoading(true);
      const link = `${server_url}/api/get_past_tournaments`;
      let res = await axios.get(link);
      if (res.data.success) {
        setTourData(res.data.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const Stour = (t, i) => {
    const img_to_str = async (avater) => {
      let result = await process_image(avater.target.files[0]);
      
      let src = document.getElementById(`view_updated_img_banner_${i}`);
      src.setAttribute("src", result);
    };

    const updater = async (e) => {
      try {
        e.preventDefault();
        console.log("called");
        toast.warning("Updating..");

        const name = e.target._name.value;
        const link = e.target.link.value;

        const banner = e.target.banner.src;

        const _id = t._id;
        const url = `${server_url}/api/update_past_tournaments`;
        let _token = localStorage.getItem("token");

        let submit_obj = {
          name,
          link,
          _id,

          banner,
          _token,
        };

        let res = await axios.post(url, submit_obj);

        if (res.data.success) {
          getData();
          return toast.success("Tournament Updated.");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong.");
      }
    };

    const delete_tour = async () => {
      try {
        let con = window.confirm(
          "Are you sure want to delete this tournament?"
        );
        if (!con) {
          return;
        }
        console.log("deleting");
        const url = `${server_url}/api/delete_past_tournaments`;
        let token = localStorage.getItem("token");
        let submit_obj = {
          _token: token,
          _id: t._id,
        };
        let res = await axios.post(url, submit_obj);
        if (res.data.success) {
          getData();
          return toast.warning("Tournament Deleted.");
        }
      } catch (error) {
        toast.error("Something went wrong.");
      }
    };
    return (
      <>
        <div key={`_${i}`} className="tour_block" data-aos={t.aos}>
          <form onSubmit={updater}>
            <div className="img">
              <div className="update_img">
                <input
                  type="file"
                  onChange={img_to_str}
                  accept="image/png, image/jpg"
                />
              </div>
              <img
                name="banner"
                id={`view_updated_img_banner_${i}`}
                src={t.banner}
                alt="avatar"
              />
            </div>
            <div className="tour_content">
              <input id="name" type="text" name="_name" defaultValue={t.name} />

              <textarea
                id="link"
                type="text"
                name="link"
                defaultValue={t.link}
                placeholder="Tournament Link"
              />
            </div>

            <button type="submit" className="button_2">
              Update
            </button>
          </form>
          <button onClick={delete_tour} className="button_2 delete">
            Delete
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="upcomming_tournaments">
        <div className="container">
          <h1>update past tournaments</h1>
          {loading ? <h1>Loading...</h1> : null}
          <div className="tour_blocks">
            {tourData && tourData.map(Stour)}
            {!tourData ? "No Data found" : null}
          </div>
        </div>
      </section>
    </>
  );
};
export default AdPreTournaments;
