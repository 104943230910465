import { toast, ToastContainer } from "react-toastify";
import { server_url } from "../../../../helpers/auth";
import axios from "axios";
import { useEffect, useState } from "react";
const AdAchievement = () => {
  document.title = "APEX || ACHIEVEMENTS";
  const [data, setData] = useState("");
  const loc = window.location;
  const getData = async () => {
    try {
      const date = `${server_url}/api/get_achievements`;

      let res = await axios.get(date);
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);
  const Add = () => {
    const submit_handler = async (e) => {
      try {
        e.preventDefault();
        const a_name = e.target.a_name.value;
        const date = e.target.date.value;
        const a_des = e.target.a_des.value;

        if (!a_name || !date || !a_des) {
          return toast.error("Fill all fields.");
        }
        const url = `${server_url}/api/add_achievements/`;
        let _token = localStorage.getItem("token");
        let obj = {
          a_name,
          date,
          a_des,
          _token,
        };
        let res = await axios.post(url, obj);
        if (res.data.success) {
          e.target.reset();
          getData();
          return toast.success(`Streamer ${a_name} added.`);
        }
      } catch (error) {
        return toast.error(
          error.response.data.message || "Something went wrong"
        );
      }
    };
    return (
      <>
        <div className="container">
          <h1>ADD ACHIEVEMENT</h1>
          <div className="add_player">
            <form onSubmit={submit_handler}>
              <div className="inputs">
                <input
                  name="a_name"
                  type="text"
                  placeholder="Achievement Name"
                />

                <input name="date" type="text" placeholder="Achievement date" />
                <textarea
                  name="a_des"
                  id=""
                  placeholder="Achievement Description"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <input type="submit" value={"ADD"} />
            </form>
          </div>
        </div>
      </>
    );
  };

  const Tr = (p, i) => {
    const delete_handler = async () => {
      try {
        let con = window.confirm("Are you sure want to delete?");
        if (!con) return;
        const date = `${server_url}/api/delete_achievements/`;
        let _token = localStorage.getItem("token");

        const submit_obj = {
          _id: p._id,
          _token,
        };
        let res = await axios.post(date, submit_obj);
        if (res.data.success) {
          toast.warn(`Player ${p.a_name} deleted.`);
          // getData();
          loc.reload();
        }
      } catch (error) {
        return toast.error(
          error.response.data.message || "Something went wrong"
        );
      }
    };
    const submit_handler = async () => {
      try {
        const a_name = document.getElementById(`_a_name_${i + 1}`).value;
        const date = document.getElementById(`_date_${i + 1}`).value;
        const a_des = document.getElementById(`_a_des_${i + 1}`).value;

        if (!a_name || !date) {
          return toast.error("Fill all fields.");
        }
        const url = `${server_url}/api/update_achievements/`;
        let _token = localStorage.getItem("token");
        let obj = {
          a_name,
          date,
          a_des,
          _id: p._id,
          _token,
        };
        let res = await axios.post(url, obj);
        if (res.data.success) {
          toast.success(`Achievement ${a_name} updated.`);
          getData();
        }
      } catch (error) {
        console.log(error);
        return toast.error(
          error?.response?.data?.message || "Something went wrong"
        );
      }
    };
    return (
      <>
        <tr key={`_d${i + 1}`}>
          <th>{i + 1}.</th>

          <td>
            <input
              className="s_inp"
              type="text"
              id={`_a_name_${i + 1}`}
              name="a_name"
              defaultValue={p.a_name}
              placeholder="Name"
            />
          </td>
          <td>
            <input
              className="s_inp"
              id={`_date_${i + 1}`}
              type="text"
              name="date"
              defaultValue={p.date}
              placeholder="date"
            />
          </td>
          <td>
            <textarea
              className="s_inp"
              id={`_a_des_${i + 1}`}
              type="text"
              name="a_des"
              defaultValue={p.a_des}
              placeholder="date"
            />
          </td>
          <td>
            <button className="button_2" onClick={submit_handler}>
              Update
            </button>
          </td>

          <td>
            <button onClick={delete_handler} className="button_2 delete">
              Delete
            </button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <h1>PREVIOUS ACHIEVEMENTS</h1>

      <div className="container">
        <div className="pre_players_wrap">
          {/* {data && data.length !== 0 ? data.map(Pre) : "No Player Found Yet!"} */}
        </div>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Achievement Name</th>
              <th scope="col">Date</th>
              <th scope="col">Description</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody id="day_tbody">
            {data && data.length !== 0 ? data.map(Tr) : "No data Found Yet!"}
          </tbody>
        </table>
      </div>

      <Add />
    </>
  );
};
export default AdAchievement;
